import baseTheme from '../base'

const MuiContainer = {
  defaultProps: {
    maxWidth: 'xxl',
  },
  styleOverrides: {
    root: {
      paddingLeft: baseTheme.spacing(1.25),
      paddingRight: baseTheme.spacing(1.25),

      [baseTheme.breakpoints.up('sm')]: {
        paddingLeft: baseTheme.spacing(1.25),
        paddingRight: baseTheme.spacing(1.25),
      },
      [baseTheme.breakpoints.up('md')]: {
        paddingLeft: baseTheme.spacing(1.5),
        paddingRight: baseTheme.spacing(1.5),
      },
      [baseTheme.breakpoints.up('lg')]: {
        paddingLeft: baseTheme.spacing(2),
        paddingRight: baseTheme.spacing(2),
      },
      [baseTheme.breakpoints.up('xxl')]: {
        paddingLeft: baseTheme.spacing(4),
        paddingRight: baseTheme.spacing(4),
      },
    },
  },
}

export default MuiContainer
