import baseTheme from '../base'

const MuiCheckbox = {
  styleOverrides: {
    root: {
      padding: baseTheme.spacing(0, 0.25),

      '& > svg': {
        fontSize: baseTheme.spacing(2),
      },
    },
  },
}

export default MuiCheckbox
