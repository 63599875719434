const styles = {
  root: {
    '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & textarea:-webkit-autofill, & textarea:-webkit-autofill:hover, & textarea:-webkit-autofill:focus, & select:-webkit-autofill, & select:-webkit-autofill:hover, & select:-webkit-autofill:focus':
      {
        border: 0,
        WebkitTextFillColor: 'var(--WebkitTextFillColor)',
        WebkitBoxShadow: '0 0 0px 1000px transparent inset',
        transition: 'background-color 5000s ease-in-out 0s',
      },

    '& .MuiInputLabel-root': {
      color: 'var(--label--color)',
      backgroundColor: 'var(--label--backgroundColor)',

      '&.Mui-error': {
        color: 'var(--label-error--color)',
        backgroundColor: 'var(--label-error--backgroundColor)',
      },

      '&.Mui-focused': {
        color: 'var(--label-focused--color)',
        backgroundColor: 'var(--label-focused--backgroundColor)',

        '&.Mui-error': {
          color: 'var(--label-focused-error--color)',
          backgroundColor: 'var(--label-focused-error--backgroundColor)',
        },
      },
    },

    '& .MuiInputBase-root': {
      color: 'var(--input--color)',
      backgroundColor: 'var(--input--backgroundColor)',

      '&:hover': {
        borderColor: 'var(--input__hover--color)',
        backgroundColor: 'var(--input__hover--backgroundColor)',
      },

      '&.Mui-focused': {
        backgroundColor: 'var(--input-focused--backgroundColor)',
      },

      '&.Mui-error': {
        color: 'var(--input-error--color)',
        backgroundColor: 'var(--input-error--backgroundColor)',

        '&:hover': {
          borderColor: 'var(--input-error__hover--borderColor)',
          backgroundColor: 'var(--input-error__hover--backgroundColor)',
        },

        '& .MuiInputBase-input': {
          color: 'var(--input-error-input--color)',
        },
      },
    },
  },
  hasIcon: {},
  noIcon: {
    '& > .MuiFormLabel-root': {
      left: (theme) => theme.spacing(0.6875),
    },
    '& .MuiFilledInput-input': {
      pl: 1.375,
    },
  },
}

export default styles
