const composeValidators = (fieldValidations) => (value) => {
  for (const [validationFn, validationMessage] of fieldValidations) {
    if (!validationFn(value)) {
      return validationMessage
    }
  }

  return ''
}

export default composeValidators
