const styles = {
  root: {
    '> img': {
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
    },
  },
}

export default styles
