const MuiTableContainer = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      borderRadius: 0,
    },
  },
}

export default MuiTableContainer
