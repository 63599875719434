import PropTypes from 'prop-types'
import { Box } from '@mui/material'

import styles from './picture.styles'

const picturePropTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  avif: PropTypes.string,
  webp: PropTypes.string,
  fallback: PropTypes.string.isRequired,
  loading: PropTypes.string,
  alt: PropTypes.string,
}

const Picture = ({
  width,
  height,
  avif,
  webp,
  fallback,
  loading,
  alt = '',
}) => {
  return (
    <Box component='picture' sx={styles.root}>
      {avif ? <source srcSet={avif} type='image/avif' /> : null}
      {webp ? <source srcSet={webp} type='image/webp' /> : null}
      <img
        width={width}
        height={height}
        loading={loading}
        src={fallback}
        alt={alt}
      />
    </Box>
  )
}

Picture.propTypes = picturePropTypes

export default Picture
