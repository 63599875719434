import { createTheme } from '@mui/material/styles'

import breakpoints from './breakpoints'
import palette from './palette'
import shadows from './shadows'
import typography from './typography'
import mixins from './mixins'

export default createTheme({
  breakpoints,
  spacing: (factor) => `${factor * 1}rem`,
  shape: { borderRadius: 8 },
  palette,
  shadows,
  typography,
  mixins,
})
