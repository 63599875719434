import PropTypes from 'prop-types'
import { Box } from '@mui/material'

import styles from './formSkinWrapper.styles'

const formSkinWrapperPropTypes = {
  skin: PropTypes.oneOf(['dark', 'light']),
  sx: PropTypes.object,
  children: PropTypes.node,
}

const FormSkinWrapper = ({ skin, sx, children, ...rest }) => (
  <Box sx={{ ...styles[skin], ...sx }} {...rest}>
    {children}
  </Box>
)

FormSkinWrapper.propTypes = formSkinWrapperPropTypes

export default FormSkinWrapper
