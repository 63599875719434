import { Box, CircularProgress as MUICircularProgress } from '@mui/material'

const CircularProgress = ({ height = 300, width = '100%' }) => (
  <Box
    sx={{
      height,
      width,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <MUICircularProgress />
  </Box>
)

export default CircularProgress
