const shadows = [
  'none',
  '0px 4px 14px rgba(60, 60, 60, 0.1)',
  '0px 2px 5px rgba(33, 33, 33, 0.2)',
  '0px 1px 0px rgba(156, 156, 156, 0.2)',
  '0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 9px rgba(33, 33, 33, 0.1)',
  'initial',
  'initial',
  'initial',
  'initial',
  'initial',
  'initial',
  'initial',
  'initial',
  'initial',
  'initial',
  'initial',
  'initial',
  'initial',
  'initial',
  'initial',
  'initial',
  'initial',
  'initial',
  'initial',
  'initial',
]

export default shadows
