import baseTheme from '../base'

const MuiIconButton = {
  styleOverrides: {
    root: {
      padding: baseTheme.spacing(0.25),
    },
  },
}

export default MuiIconButton
